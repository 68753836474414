<div class="position-relative">
  <input
    [formControl]="control"
    [class]="
      'form-control input searchbar' + ' ' + (darkMode === true ? 'dark' : '')
    "
    [placeholder]="placeholder"
    [readonly]="readonly"
    #searchInput
  />
  <span
    (click)="!readonly ? (searchInput.value = '') : null; handleClear()"
    *ngIf="searchInput.value.length > 0"
    class="handleClearBtn"
    title="Clear search filter"
    >×</span
  >
</div>
