import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import { EditableDate } from './editable-date.interface';
import { NgbDateTimeStruct } from './date-input.consts';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(customParseFormat);

// Accepts dates in the format YYYY-MM-DD or DD-MM-YYYY
const DATE_REGEX = /([0-9]{4}[-|/](0[1-9]|1[0-2])[-|/]([0-2]{1}[0-9]{1}|3[0-1]{1})|([0-2]{1}[0-9]{1}|3[0-1]{1})[-|/](0[1-9]|1[0-2])[-|/][0-9]{4})/;

export class DateUtils {
  static convertDayJsDateToNgbDate(date: dayjs.Dayjs): NgbDate {
    if (!date) {
      return null;
    }

    return {
      year: date.year(),
      month: date.month() + 1,
      day: date.date(),
    } as NgbDate;
  }

  static convertDateTimeStringToNgbDateTime(
    dateTime: string
  ): NgbDateTimeStruct {
    if (!dateTime) {
      return null;
    }

    if (
      !dateTime.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\+|-)\d{2}:\d{2}$/)
    ) {
      throw new Error('Invalid date time string format: ' + dateTime);
    }

    const [dateString, timeString] = dateTime.split('T');
    const [year, month, day] = dateString.split('-').map(Number);
    const [hour, minute] = timeString.split(':').map(Number);
    return {
      year,
      month,
      day,
      hour,
      minute,
      second: 0,
    };
  }

  static convertDayJSDateTimeToNgbDateTime(
    dayJsDate: dayjs.Dayjs
  ): NgbDateTimeStruct {
    if (!dayJsDate) {
      return null;
    }

    return {
      year: dayJsDate.year(),
      month: dayJsDate.month() + 1,
      day: dayJsDate.date(),
      hour: dayJsDate.hour(),
      minute: dayJsDate.minute(),
      second: 0,
    };
  }

  static convertNgbDateToDate(date: NgbDate | NgbDateStruct): Date {
    if (!date) {
      return null;
    }

    if (date?.day && date?.month && date?.year) {
      return dayjs(
        `${date.year}-${String(date.month).padStart(2, '0')}-${String(
          date.day
        ).padStart(2, '0')}`
      ).toDate();
    }
  }

  static convertDateToNgbDate(date: Date): NgbDate {
    if (!date) {
      return null;
    }

    const dayJsDate = dayjs(date);
    return {
      year: dayJsDate.year(),
      month: dayJsDate.month() + 1,
      day: dayJsDate.date(),
    } as NgbDate;
  }

  static convertStringToDate(date: string, format?: string): Date {
    if (!date) {
      return null;
    }
    return dayjs(date, format).toDate();
  }

  static convertStringToNgbDate(date: string, format?: string): NgbDate {
    if (!date) {
      return null;
    }

    return DateUtils.convertDateToNgbDate(
      DateUtils.convertStringToDate(date, format)
    );
  }

  static formatStringDate(
    dateStr: string,
    format: string = 'DD/MM/YYYY'
  ): string {
    return dayjs(dateStr).format(format);
  }

  static formatDate(date: Date, format: string = 'DD/MM/YYYY'): string {
    return dayjs(date).format(format);
  }

  static getPreviousWeekday(today = dayjs()): dayjs.Dayjs {
    do {
      today = today.subtract(1, 'day');
    } while (today.day() === 0 || today.day() === 6);

    return today;
  }

  static getPreviousWeekdayAsNgbDate(baseDate = dayjs()): NgbDate {
    return DateUtils.convertDayJsDateToNgbDate(
      DateUtils.getPreviousWeekday(baseDate)
    );
  }

  static getPreviousWeekdayAsDate(today = dayjs()): Date {
    return DateUtils.getPreviousWeekday(today).toDate();
  }

  static compareDates(date1: NgbDateStruct, date2: NgbDateStruct): number {
    if (!date1 || !date2) {
      return null;
    }

    const date1Dayjs = DateUtils.convertNgbDateToDayJs(date1);
    const date2Dayjs = DateUtils.convertNgbDateToDayJs(date2);

    return date1Dayjs.diff(date2Dayjs);
  }

  static convertNgbDateToDayJs(date: NgbDate | NgbDateStruct): dayjs.Dayjs {
    if (!date) {
      return null;
    }

    return dayjs(DateUtils.convertNgbDateToString(date));
  }

  static isWeekend(date: NgbDateStruct): boolean {
    if (!date) {
      return null;
    }

    const convertedDate = DateUtils.convertNgbDateToDayJs(date);
    return convertedDate.day() === 0 || convertedDate.day() === 6;
  }

  static convertNgbDateToString(date: NgbDateStruct): string {
    if (!date) {
      return null;
    }

    if (typeof date === 'string') {
      return date;
    }

    return `${date.year}-${String(date.month).padStart(2, '0')}-${String(
      date.day
    ).padStart(2, '0')}`;
  }

  static getStringFromDateObject(date: NgbDate): string {
    if (!date?.year) {
      return null;
    }
    const dateString = new Date(
      `${date.month}/${date.day}/${date.year}Z` // corrects time zone error that was causing dates to save -1 day
    ).toISOString();
    return dateString.substring(0, 10);
  }

  static getStringFromEditableDateObject(
    date: EditableDate
  ): {
    original: string | null;
    edited: string | null;
  } {
    if (!date) {
      return null;
    }

    return {
      original: this.getStringFromDateObject(date.original as NgbDate),
      edited: this.getStringFromDateObject(date.edited as NgbDate),
    };
  }

  static getStringWithOffsetFromDateTimeObject(
    date: NgbDateTimeStruct,
    timezone: string
  ): string {
    if (!date?.year) {
      return null;
    }

    const pad = (number: number) => (number < 10 ? `0${number}` : number);

    const year = date.year;
    const month = pad(date.month);
    const day = pad(date.day);
    const hour = pad(date.hour);
    const minute = pad(date.minute);
    const second = '00';

    const dateTimeString = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    return dayjs.tz(dateTimeString, timezone).format('YYYY-MM-DDTHH:mm:ssZ');
  }

  static resetClock(date: dayjs.Dayjs): dayjs.Dayjs {
    if (!date) {
      return;
    }

    return date.set('hour', 0).set('minute', 0).set('second', 0);
  }

  static isDate(value: any): boolean {
    if (!value || (typeof value !== 'string' && typeof value !== 'object')) {
      return false;
    }

    const isDate = value instanceof Date;
    const isDateStruct = value.year && value.month && value.day;
    const isDateString =
      typeof value === 'string' &&
      value.match(DATE_REGEX)?.length &&
      new Date(value).toDateString() !== 'Invalid Date';

    return isDate || isDateStruct || isDateString;
  }

  static convertDateKindToString(
    date: Date | NgbDateStruct | NgbDateTimeStruct | dayjs.Dayjs,
    format: string = 'DD/MM/YYYY'
  ): string {
    if (!date) {
      return null;
    }

    if (date instanceof Date) {
      return DateUtils.formatDate(date, format);
    }

    if (dayjs.isDayjs(date)) {
      return date.format(format);
    }

    const asNgbDateTime = date as NgbDateTimeStruct;
    const isNgbDate =
      asNgbDateTime.year && asNgbDateTime.month && asNgbDateTime.day;

    const isDateStructWithTime =
      isNgbDate && asNgbDateTime.hour && asNgbDateTime.minute;

    if (isDateStructWithTime) {
      return dayjs(
        `${asNgbDateTime.year}-${asNgbDateTime.month}-${asNgbDateTime.day}T${asNgbDateTime.hour}:${asNgbDateTime.minute}`
      ).format(format);
    }

    if (isNgbDate) {
      const newDate = dayjs()
        .set('year', asNgbDateTime.year)
        .set('month', asNgbDateTime.month - 1)
        .set('date', asNgbDateTime.day);
      return newDate.format(format);
    }
  }

  static areEqual(date1: NgbDateStruct, date2: NgbDateStruct): boolean {
    if (!date1 || !date2) {
      return false;
    }

    return (
      date1.year === date2.year &&
      date1.month === date2.month &&
      date1.day === date2.day
    );
  }
}
