import {
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlValueAccessorConnector } from '../controlvalue-accessor.connector';

@Component({
  selector: 'app-dc-search',
  templateUrl: './dc-search.component.html',
  styleUrls: ['./dc-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DcSearchComponent,
      multi: true,
    },
  ],
})
export class DcSearchComponent extends ControlValueAccessorConnector {
  @Input() placeholder: string;
  @Input() readonly: boolean;
  @Input() darkMode: boolean;
  @Output() clearFilter = new EventEmitter();
  constructor(injector: Injector) {
    super(injector);
  }

  handleClear() {
    this.clearFilter.emit(null);
  }
}
