import {
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DateUtils } from './date.utils';
import { ControlValueAccessorConnector } from '../controlvalue-accessor.connector';

@Component({
  selector: 'app-dc-date-input-range',
  templateUrl: './dc-date-input-range.component.html',
  styleUrls: ['./dc-date-input-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DcDateInputRangeComponent,
      multi: true,
    },
  ],
})
export class DcDateInputRangeComponent extends ControlValueAccessorConnector {
  @Input() id: string;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() fromPlaceholder: string = '';
  @Input() toPlaceholder: string = '';
  @Input() darkMode: boolean;
  @Input() showClear: boolean;

  @Output() dateRangeOut = new EventEmitter();

  rangeDates = new UntypedFormControl();

  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;

  destroy$ = new Subject<boolean>();

  onChange: (value: any) => void;
  onTouched: () => void;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.control.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value) => {
        this.onDateChanged(value);
      });
    this.placeholder = this.fromPlaceholder || this.placeholder;
  }

  onFocus(): void {
    this.updateDateStyles();
  }

  onDateChanged(date: Date[]): void {
    if (date) {
      const [startDate, endDate] = date;

      if (startDate) {
        const dateStartAsNgbDate = DateUtils.convertDateToNgbDate(startDate);
        this.fromDate = dateStartAsNgbDate;
      } else {
        this.fromDate = null;
      }

      if (endDate) {
        const dateEndAsNgbDate = DateUtils.convertDateToNgbDate(endDate);
        this.toDate = dateEndAsNgbDate;
      } else {
        this.toDate = null;
      }
    } else {
      this.fromDate = null;
      this.toDate = null;
    }

    const value = { from: this.fromDate, to: this.toDate };

    this.updateDateStyles();
    this.dateRangeOut.emit(value);
  }

  updateDateStyles(): void {
    setTimeout(() => {
      document
        .querySelectorAll('.p-highlight-middle')
        .forEach((el: HTMLElement) => {
          el.classList.remove('p-highlight-middle');
        });

      document
        .querySelectorAll('.p-highlight')
        .forEach(
          (el: HTMLElement, index: number, elements: NodeListOf<Element>) => {
            if (index > 0 && index < elements.length - 1) {
              el.parentElement.classList.add('p-highlight-middle');
            }
          }
        );
    });
  }

  clear(): void {
    this.control.setValue(null);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
