<div>
  <label *ngIf="label" [for]="id">{{ label }}</label>

  <div [class]="'input-group' + ' ' + (darkMode === true ? 'dark' : '')">
      <p-calendar 
        #myRangeInput
        [formControl]="control"
        selectionMode="range"
        [showIcon]="true"
        dateFormat="dd/mm/yy"
        [numberOfMonths]="2"
        [showOtherMonths]="false"
        (onFocus)="onFocus()"
        (onClear)="clear()"
        [showClear]="showClear"
        [placeholder]="placeholder"
        [readonlyInput]="true">
      </p-calendar>
      <span class="clear-button pi pi-times" (click)="clear()"></span>
  </div>
</div>
